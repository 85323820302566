import { Component, OnInit } from '@angular/core';
import { Product } from '../models/product';
import { ProductService } from '../services/product.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms 500ms ease-out', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('300ms ease-out', style({ opacity: 0 }))
        ])
      ]
    )
  ]
})
export class ProductsComponent implements OnInit {

  // Attributes
  currentPage: number = 1;
  pageSize: number = 16;
  productsList: Product[] = [];

  // Properties
  get maxPage(): number {

    if (this.productsList == null) {
      return 1;
    }

    return Math.ceil(this.productsList.length / this.pageSize);
  }

  get products(): Product[] | null {
    let skip = this.pageSize * (this.currentPage - 1);
    let take = this.pageSize;

    if (this.productsList == null || this.productsList.length == 0) {
      return null;
    }

    return this.productsList.skip(skip).take(take);
  }

  get pages(): number[] {
    let numbers = this.range(this.currentPage - 1, this.currentPage + 1);

    if (this.maxPage == 0 || this.maxPage == 1){
      return [1];
    }
    else if (this.maxPage == 1 || this.maxPage == 2) {
      return this.range(1, 2);
    }
    else if (this.currentPage - 1 == 0) {
      for (let i = 0; i < numbers.length; i++) {
        numbers[i]++;
      }
    }
    else if (this.currentPage == this.maxPage) {
      for (let i = 0; i < numbers.length; i++) {
        numbers[i]--;
      }
    }

    return numbers;
  }

  range = (start: number, end: number) => [...Array(end - start + 1)].map((_, i) => start + i);

  // Constructor
  constructor(private productService: ProductService) { }

  // Methods
  ngOnInit(): void {
    this.getProducts();
  }

  getProducts(): void {
    this.productService.products.subscribe(products => {
      this.productsList = products;
      this.currentPage = 1;
    });
    this.productService.refreshProducts();
  }

  onProductClick(product: Product): void {
    window.open('https://berkleba-v2.appec.nl/producten/?artikelNummer=' + product.articleNumber, '_blank');
  }

  toPage(page: number): void {
    if (page <= 0) {
      page = 1;
    }
    else if (page >= this.maxPage) {
      page = this.maxPage
    }

    this.currentPage = page;
  }
}
