import { Component, OnInit } from '@angular/core';
import { Question } from '../models/question';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  questionsList: Question[] = [];
  page = 1;

  selectedAnswerIds: number[] = [];
  expendedQuestionIds: number[] = [];

  showAll = false;

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.getFilter();
  }

  getFilter() {
    this.productService.questions.subscribe(list => {
      this.questionsList = list
    })
  }

  submitFilter() {
    this.productService.submitFilter(this.selectedAnswerIds);
  }

  checkboxChanged(e: any, id: number) {
    if (e.target.checked) {
      this.selectedAnswerIds.push(id);
    }
    else {
      const index = this.selectedAnswerIds.indexOf(id, 0);
      this.selectedAnswerIds.splice(index, 1);
    }

    this.productService.submitFilter(this.selectedAnswerIds);
  }

  previousPage(): void {
    if (this.page == 1) {
      return;
    }

    this.page = this.page - 1;
  }

  nextPage(): void {
    if (this.page == 3) {
      return;
    }

    this.page = this.page + 1;
  }

  showMore(): void {
    this.showAll = true;
  }

  showLess(): void {
    this.showAll = false;
  }

  expandQuestion(id: number): void {
    this.expendedQuestionIds.push(id);
  }

  collapseQuestion(id: number): void {
    let index = this.expendedQuestionIds.indexOf(id, 0);
    this.expendedQuestionIds.splice(index, 1);
  }

  resetFilter(): void {
    this.selectedAnswerIds = [];
    this.submitFilter();
    this.page = 1;
  }

  get isSingle(): Boolean {
    return this.page == 1 || this.page == 2;
  }

  get question(): Question | null {
    let index = this.page - 1;

    if (this.questionsList == null || this.questionsList.length < index + 1) {
      return null;
    }

    return this.questionsList[this.page - 1];
  }

  get questions(): Question[] | null {
    if (this.questionsList == null || this.questionsList.length == 0){
      return null;
    }

    if (this.showAll) {
      return this.questionsList?.skip(2);
    }
    else {
      return this.questionsList?.skip(2).take(6);
    }

  }

  get questionsCount(): number {
    if (this.questions) {
      return this.questionsList.length;
    }

    return 0;
  }

}
