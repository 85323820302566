<div class="container-fluid berkleba-filter-container">
  <!-- for multiple questions -->
  <div class="row berkleba-filter" *ngIf="!isSingle">
    <div class="col-12 col-sm-6 col-lg-4 berkelba-filter-table-container" *ngFor="let question of questions">
      <div class="berkleba-filter-table-title">
        <h3>
          {{question.text}}
        </h3>
        <a *ngIf="question.answers.length > 4" class="btn" (click)="this.expendedQuestionIds.includes(question.id) ? collapseQuestion(question.id) : expandQuestion(question.id)">
          <i *ngIf="this.expendedQuestionIds.includes(question.id)" class="fas fa-chevron-up"></i>
          <i *ngIf="!this.expendedQuestionIds.includes(question.id)" class="fas fa-chevron-down"></i>
        </a>
      </div>
      <div class="row" [class]="this.expendedQuestionIds.includes(question.id) ? 'row-expanded' : ''">
        <div class="col-6" *ngFor="let answer of question.answers; let answerIndex = index">
          <div *ngIf="this.expendedQuestionIds.includes(question.id) || answerIndex < 4" class="form-check">
            <label *ngIf="answer.enabled" class="checkbox-container" for="answer_{{ answer.id }}">
              {{ answer.text }}
              <input type="checkbox" value="{{ answer.id }}" id="answer_{{ answer.id }}" [checked]="this.selectedAnswerIds.includes(answer.id)" (change)="checkboxChanged($event, answer.id)">
              <span class="checkmark"></span>
            </label>
            <label *ngIf="!answer.enabled" class="checkbox-container checkbox-container-disabled">
              {{ answer.text }}
              <input type="checkbox" value="-1" id="answer_{{ answer.id }}" disabled>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- for single questions -->
  <div class="row berkleba-filter" *ngIf="isSingle">
    <h1>
      {{question?.text}}
    </h1>
    <div class="col-12 col-sm-6 col-md-4" *ngFor="let answer of question?.answers">
      <div class="form-check">
        <label *ngIf="answer.enabled" class="checkbox-container" for="answer_{{ answer.id }}">
          {{ answer.text }}
          <input type="checkbox" value="{{ answer.id }}" id="answer_{{ answer.id }}" [checked]="this.selectedAnswerIds.includes(answer.id)" (change)="checkboxChanged($event, answer.id)">
          <span class="checkmark"></span>
        </label>

        <label *ngIf="!answer.enabled" class="checkbox-container checkbox-container-disabled">
          {{ answer.text }}
          <input type="checkbox" value="-1" id="answer_{{ answer.id }}" disabled>
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>

  <div class="row justify-content-between">
    <div class="col-3 berkleba-filter-navigation" [style]="{'opacity': (page > 1) ? '1' : '0'}" (click)="previousPage()">
      <i class="fas fa-arrow-left"></i>&nbsp;<span class="d-none d-sm-inline-block">Vorige</span>
    </div>
    <div class="col-3 berkleba-filter-navigation" *ngIf="!isSingle && questionsCount > 6">
      <a class="btn" (click)="this.showAll ? showLess() : showMore()">
        {{this.showAll ? "Minder specificaties" : "Meer specificaties"}}
      </a>
    </div>
    <div class="col-3 berkleba-filter-navigation">
      <a class="btn" (click)="resetFilter()">
        Filter resetten
      </a>
    </div>
    <div class="col-3 berkleba-filter-navigation" [style]="{'opacity': (page < 3) ? '1' : '0'}" (click)="nextPage()">
      <span class="d-none d-sm-inline-block">Volgende</span>&nbsp;<i class="fas fa-arrow-right"></i>
    </div>
  </div>
</div>
