import './prototypes';
import { Component } from '@angular/core';
import { ProductService } from '../app/services/product.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Berkleba-ProductSelector';

  loading = true;

  constructor(private productService: ProductService) {
  }

  ngOnInit(): void {
    this.productService.products.subscribe(products => {
      if (products != null && products.length > 0){
        this.loading = false;
      }
    })
  }
}
