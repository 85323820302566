interface Array<T> {
    take(limit: number): Array<T>;
    skip(skip: number): Array<T>;
}

Array.prototype.take = function (limit: number): any[] {
  return this.filter((x,i) => {
    if(i <= (limit-1)) {
      return true
    }
    return false
  })
}

Array.prototype.skip = function(skip: number): any[] {
  return this.filter((x,i) => {
    if(i > (skip-1)) {
      return true
    }
    return false
  })
}
