<div class="container-fluid">
  <div class="row">
    <div *ngFor="let product of products" @inOutAnimation class="col-lg-3 col-md-4 col-sm-6 col-12 product-container" (click)="onProductClick(product)">
      <h1>
        {{ product.productName }}
      </h1>
      <div class="product-image" style="background-image: url('https://berklebaapi.appec.nl/images/{{product.articleNumber}}.jpg');"></div>
      <div class="product-info">
        <span class="product-article-number">
          <button class="btn">
            Artikelnr.&nbsp;<strong>{{ product.articleNumber }}</strong>
          </button>
        </span>
      </div>
    </div>
    <nav>
      <ul class="pagination justify-content-center" id="product_navigation">
        <li class="page-item disable-select">
          <a class="page-link berkleba-control" href="#product_navigation" [style]="{'opacity': (currentPage > 1) ? '1' : '0'}" (click)="toPage(currentPage - 1)" tabindex="-1">
            <i class="fas fa-arrow-left"></i>&nbsp;Vorige pagina
          </a>
        </li>
        <li class="page-item" [class.active]="currentPage === page" *ngFor="let page of pages">
          <a class="page-link berkleba-page" href="#product_navigation" (click)="toPage(page)">{{ page }}</a>
        </li>
        <li class="page-item disable-select">
          <a class="page-link berkleba-control" href="#product_navigation" [style]="{'opacity': (currentPage < maxPage) ? '1' : '0'}" (click)="toPage(currentPage + 1)" tabindex="-1">
            Volgende pagina&nbsp;<i class="fas fa-arrow-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
